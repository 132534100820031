a {
  text-decoration: none;
}

.navListItem,
.navList {
  list-style: none;
  margin: 0;
  padding: 0;
}
.nav {
  background-color: var(--header-color);
  padding: 10px 0;
  display: flex;
  font-size: 20px;
  color: var(--text-color);
  font-weight: 600;
  box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
}

.navList {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.navListItem {
  margin: 15px;
  color: var(--text-color);
}
.logo {
  display: flex;
  align-items: center;

  font-size: 32px;
}
.theme_button {
  width: 60px;
  height: 30px;
  padding: 4px;
  border-radius: 20px;
  background-color: orange;
  border: 2px solid var(--header-color);
  transition: all 0.3s ease-in-out;
  margin: 10px 0px;
}
.slider {
  height: 30px;
  width: 30px;
  background-color: black;
  border-radius: 50%;
  transition: all 0.3s ease-in-out;
}

.slider.dark {
  /* float: right; */
  margin-left: 30px;
  background-color: white;
}
.theme_button.dark {
  background-color: orange;
}

.nav {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

@media screen and (min-width: 300px) and (max-width: 600px) {
  .nav {
    font-size: 14px;
  }
  .theme_button {
    width: 30px;
    height: 15px;
    padding: 2px;
    border-radius: 10px;
    background-color: orange;
    border: 1px solid var(--header-color);
    transition: all 0.3s ease-in-out;
    margin: 10px 0px;
  }
  .slider {
    height: 15px;
    width: 15px;
  }

  .slider.dark {
    /* float: right; */
    margin-left: 15px;
    background-color: white;
  }
  .theme_button.dark {
    background-color: orange;
  }

  .nav {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  .logo {
    font-size: 25px;
  }
  .navListItem {
    margin: 10px;
  }
}

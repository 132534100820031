[data-theme="light"] {
  --header-color: #ffffff;
  --text-color: #333;
  --background-color: #e1e1e1;
}
[data-theme="dark"] {
  --header-color: #202020;
  --text-color: #ffffff;
  --background-color: #333;
}
* {
  margin: 0;
  padding: 0;
}
.home,
.about,
.contactus,
.projects {
  background-color: var(--background-color);
  height: 100%;
  color: var(--text-color);
}
body {
  height: 100%;
}
